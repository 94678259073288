import React from 'react';
import './Footer.css'; 


function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="Footer" style={{ textAlign: 'center', padding: '20px' }}>
      <p>&copy; {currentYear} <a href="/">devonbrackbill.com</a></p>
    </footer>
  );
}

export default Footer;
