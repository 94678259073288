import React from 'react';
import CenteredContent from '../components/CenteredContent';
import ImageTTF from '../imgs/talktofiles.png'
import ImageSpryDispatch from '../imgs/sprydispatch.png'
import { FaBookReader, FaHorseHead } from 'react-icons/fa';

function PortfolioPage() {
  return (
    <div>
        <CenteredContent>
            <h1>Portfolio</h1>

            <p>I've founded and developed several online companies, including:</p>

              {/* Talk To Files */}
              <a href="https://www.talktofiles.com">
                <h2><FaBookReader size={32} style={{ color: '#3899EB'}}/> Talk To Files</h2></a>
              <p>
                Talk to Files lets users chat with PDFs, Word Docs, and other files. It provides highly accurate responses based on the document a user interacts with, and it cites its sources so users can trust the answer. It uses OpenAI's API. It is specifically tuned to financial documents, and it automatically extracts financial data and automatically summarizes documents.
              </p>
              <p>
                <strong>Role:</strong> Founder, Developer.
              </p>
              <p>

              </p>
              <p>
                <strong>Founded:</strong> January 2023.
              </p>
              <p>
                <strong>URL:</strong> <a href="https://www.talktofiles.com">www.talktofiles.com</a>.
              </p>
              <p>
                <strong>Tools:</strong> Django, React, OpenAI API, Stripe.
                <a href="https://www.talktofiles.com">
                  <img src={ImageTTF} alt="Talk To Files landing page" style={{ width: '100%', height: '25%', marginRight: '10px' }} />
                </a>
              </p>

              <hr></hr>

              {/* Spry Dispatch */}

              <a href="https://www.sprydispatch.com">
                <h2><FaHorseHead size={32} style={{ color: '#5F4DEE' }}/> Spry Dispatch</h2>
              </a>
              <p>
                Spry Dispatch is a dispatching software for service companies. It allows service companies to manage their jobs, field technicians, and customers. It has a mobile app on iOS and Android for field technicians to manage their jobs and a web app for office staff to manage the company.
              </p>
              <p>
                <strong>Role:</strong> Founder, Developer.
              </p>
              <p>
                <strong>Founded:</strong> March 2022.
              </p>
              <p>
                <strong>URL:</strong> <a href="https://www.sprydispatch.com">www.sprydispatch.com</a>.
              </p>
              <p>
                <strong>Tools:</strong> Django, React, React Native mobile development (Android and iOS), Google Maps API, Stripe.
              </p>
              <p>
                <a href="https://www.sprydispatch.com">
                  <img src={ImageSpryDispatch} alt="Talk To Files landing page" style={{ width: '100%', height: '25%', marginRight: '10px' }} />
                </a>
              </p>
        </CenteredContent>
      {/* Add your content */}
    </div>
  );
}

export default PortfolioPage;
