import React from 'react';
import CenteredContent from '../components/CenteredContent';

function PublicationsPage() {
  return (
    <div>
        <CenteredContent>
          <h1>Research</h1>
            <p>
            My academic research sits at the intersection of the social sciences, physics, and computer science. I am interested in how individual actions give rise to collective phenomena in large populations. Applications include the complex interplay of social forces that produce <a href="http://www.pnas.org/content/early/2017/06/06/1615978114.abstract"><b>collective intelligence</b></a>, <a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0237978"><b>group problem-solving</b></a>, <a href="http://science.sciencemag.org/content/360/6393/1116"><b>consensus formation</b></a>, and <b>social norms</b> in human social systems.
            </p>

            <p>
              To understand the dynamics of complex systems, I rely on agent-based models to gain theoretical traction on a social behavior. Then, I test these ideas using online experiments to see if these theories hold true.
            </p>

            <p>
              My <strong>dissertation</strong>, which was supported by the <a href="http://www.nsf.gov/awardsearch/showAward?AWD_ID=1519026&HistoricalAwards=false">National Science Foundation (NSF)</a> and the <a href="http://riskcenter.wharton.upenn.edu/russell-ackoff-doctoral-student-fellowships/">Wharton Risk Center Russell Ackoff Fellowship</a>, examined how the structure of collaboration networks affects the ability of groups to solve complex problems.
            </p>
            <hr />
          <h1>Publications</h1>

    <p>
      <strong>Devon Brackbill</strong> and Damon Centola. (2020). Impact of network structure on collective learning: An experimental study in a data science competition. <em>PLOS ONE</em>. <a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0237978">article</a>.
    </p>
    <p>
      Damon Centola, Joshua Becker, <strong>Devon Brackbill</strong>, and Andrea Baronchelli. (2018). Experimental evidence for tipping points in social convention. <em>Science</em>. <a href="http://science.sciencemag.org/content/360/6393/1116">article</a>. <a href="https://ndg.asc.upenn.edu/wp-content/uploads/2018/06/Centola-et-al.-2018-Science.-Tipping-Point.pdf">pdf</a>.
    </p>
    <p>
      Joshua Becker, <strong>Devon Brackbill</strong>, and Damon Centola. (2017). Network dynamics of social influence in the wisdom of crowds. <em>Proceedings of the National Academy of Sciences</em>. <a href="http://www.pnas.org/content/early/2017/06/06/1615978114.abstract">article</a>. <a href="http://www.pnas.org/content/pnas/early/2017/06/06/1615978114.full.pdf">pdf</a>. <a href="https://hbr.org/2019/07/research-for-crowdsourcing-to-work-everyone-needs-an-equal-voice">COVERAGE in Harvard Business Review</a>.
    </p>
    <p>
      Jingwen Zhang, <strong>Devon Brackbill</strong>, Sijia Yang, Joshua Becker, Natalie Herbert, and Damon Centola. (2016). Support or Competition? How online social networks increase physical activity: A randomized controlled trial. <em>Preventative Medicine Reports</em>. <a href="http://www.sciencedirect.com/science/article/pii/S2211335516300936">article</a>.
    </p>
    <p>
      Jingwen Zhang, <strong>Devon Brackbill</strong>, Sijia Yang, Damon Centola. (2015). Efficacy and Causal Mechanism of an Online Social Media Intervention to Increase Physical Activity: Results of a Randomized Controlled Trial. <em>Preventative Medicine Reports</em>, 2: 651-657. <a href="http://www.sciencedirect.com/science/article/pii/S2211335515001072">article</a>. <a href="../PreventativeMedRep.pdf">pdf</a>.
    </p>
    <p>
      Jingwen Zhang, <strong>Devon Brackbill</strong>, Sijia Yang, Damon Centola. (2015). Identifying the effects of social media on health behavior: Data from a large-scale online experiment. <em>Data in Brief</em>, 5: 453-457. <a href="http://www.ncbi.nlm.nih.gov/pmc/articles/PMC4610960/">article</a>.
    </p>
    <p>
      <strong>Devon Brackbill</strong>, Diana C. Mutz, and Edward D. Mansfield. Effects of the Great Recession on American Attitudes toward Trade. <em>British Journal of Political Science</em> (2016). <a href="https://www.cambridge.org/core/journals/british-journal-of-political-science/article/effects-of-the-great-recession-on-american-attitudes-toward-trade/343F5021864097B6385C6E0C2E8037C4">article</a>.
    </p>
    <hr />
  <h1>Grants</h1>
    <p>
      National Science Foundation. Doctoral Dissertation Improvement Grant, Sociology ($11,912), March 2015. "Designing Collaboration Networks to Optimize Collective Problem-Solving" <a href="http://www.nsf.gov/awardsearch/showAward?AWD_ID=1519026&HistoricalAwards=false">Award information</a>.
    </p>
    <p>
      Wharton Russell Ackoff Doctoral Student Fellowship for Research on Human Decision Processes and Risk Management, Wharton, University of Pennsylvania ($3,500 direct), April 2015. "Designing Collaboration Networks to Optimize Collective Problem-Solving"
    </p>
    <p>
      Annenberg National Health Communication Study Seed Grant for Innovative Health Research ($35,000 direct), Fall 2013. "How Online Networks Impact Health Outcomes: An Experimental Approach" PI, Damon Centola; Co-Investigators, <strong>Devon Brackbill</strong>, Sijia Yang, Jingwen Zhang
    </p>
    <hr />
    <h1>Presentations</h1>
    <p>
      Joshua Becker, Damon Centola, and <strong>Devon Brackbill</strong>. "Network dynamics of social influence in the wisdom of crowds." American Sociological Association. Philadelphia, PA. August 2018. Mathematical Sociology Honorable Mention Award.
    </p>
    <p>
      <strong>Devon Brackbill</strong>, Joshua Becker, and Damon Centola. "The Danger of Opinion Leaders: How Social Influence Affects the Wisdom of Crowds." American Sociological Association: Mathematical Sociology. Seattle, WA. August, 2016 (talk).
    </p>
    <p>
      <strong>Devon Brackbill</strong>, Joshua Becker, and Damon Centola. "The Danger of Opinion Leaders: How Social Influence Affects the Wisdom of Crowds." International Conference on Computational Social Science. Kellogg School of Management, Northwestern University, Evanston, IL. June 24-26, 2016 (talk). <a href="https://mediasite.kellogg.northwestern.edu/Mediasite/Play/07ce0b2e41c14ee1bf208223d319b4c71d?catalog=1533bdef-0c88-4513-ad97-5fce50c92e62">video</a> (see 28 minute mark).
    </p>
    <p>
      <strong>Devon Brackbill</strong>. "The Network Structure of Collective Innovation." Network Science Institute, Northeastern University. Boston, MA. March 16, 2016 (invited talk).
    </p>
    <p>
      Jingwen Zhang, <strong>Devon Brackbill</strong>, Sijia Yang, Damon Centola. "Efficacy and Causal Mechanism of an Online Social Network Intervention to Increase Physical Activity." American Public Health Association. Chicago, IL. November 2015 (talk).
    </p>
    <p>
      <strong>Devon Brackbill</strong>, Joshua Becker, and Damon Centola. "The Danger of Opinion Leaders: How Centralization Undermines the Wisdom of Crowds." Workshop on Information in Networks. NYU Stern, New York, NY. October 2015.
    </p>
    <p>
      <strong>Devon Brackbill</strong>. "Engineering Behavior Change through Social Media." International Conference on Computational Social Science. Helsinki, Finland. June 2015. <a href="https://youtu.be/auvv9MHTzfs?t=59m5s">video</a>.
    </p>
    <p>
      <strong>Devon Brackbill</strong>. "Designing Collaboration Networks for Collective Problem Solving." NetSci. Zaragoza, Spain. June 2015.
    </p>
    <p>
      Jingwen Zhang, <strong>Devon Brackbill</strong>, Sijia Yang, Damon Centola. "The Causal Impact of Social Media on Physical Activity: A Randomized Controlled Trial." International Communication Association. San Juan, Puerto Rico. May 2015.
    </p>
    <p>
      Diana C. Mutz, Edward Mansfield, and <strong>Devon Brackbill</strong>. "Origins of American Attitudes Toward Globalization: The Role of Mass Media." American Political Science Association Annual Meeting. Chicago, IL. August 31, 2013.
    </p>
    <p>
      <strong>Devon Brackbill</strong>, Andrew Daniller, Alex Garlick, and Laura Silver. "Why Do People Hate the Media: A Survey-Experimental Investigation." Midwest Political Science Association. Chicago, IL. April 12, 2013.
    </p>
    <p>
      Doug Allen, <strong>Devon Brackbill</strong>, Andrew Daniller, Alex Garlick, Diana C. Mutz, Laura Silver, and Ashley Tallevi. "A Re-examination of Waning Trust in the News Media." Annenberg Colloquium (Invited Talk). University of Pennsylvania, Philadelphia, PA. November 2012.
    </p>





        </CenteredContent>
    </div>
  );
}

export default PublicationsPage;
