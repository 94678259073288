import React, { useState, useEffect } from 'react';
import CenteredContent from '../components/CenteredContent';
import remarkMath from 'remark-math';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import { URL } from './constants';

function BlogIndexPage() {

  // fetch from backend
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch(`${URL}/api/blog/`)
      .then(res => res.json())
      .then(posts => setPosts(posts));
  }, []);


  return (
    <div>
        <CenteredContent>

    <h1>Blog</h1>
      <p>
        {posts.map((post, index) => (
          <div key={post.id}>
          <a href={`/blog/${post.url_slug}`}><h2>{post.title}</h2></a>
          <p>{post.date_created}</p>
          <ReactMarkdown
            children={post.summary}
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeRaw, rehypeKatex]}
            />
            {index < posts.length - 1 && <hr />} {/* Insert <hr> except for the last item */}
        </div>
        ))}
      </p>
    
        </CenteredContent>
    </div>
  );
}

export default BlogIndexPage;
