import logo from './logo.svg';
import './App.css';
import CustomNavbar from './components/CustomNavbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
// import AboutPage from './pages/AboutPage';
import PublicationsPage from './pages/PublicationsPage';
import ResumePage from './pages/ResumePage';
import AIMLPage from './pages/AIMLPage';
import ContactPage from './pages/ContactPage';
import PortfolioPage from './pages/PortfolioPage';
import Footer from './components/Footer';
import BlogPage from './pages/BlogPage';
import BlogIndexPage from './pages/BlogIndexPage';

function App() {
  return (
    <Router>
      <CustomNavbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/about" element={<AboutPage />} /> */}
        <Route path="/publications" element={<PublicationsPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/resume" element={<ResumePage />} />
        <Route path="/ai-ml" element={<AIMLPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blog" element={<BlogIndexPage />} />
        <Route path="/blog/:url_slug" element={<BlogPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
