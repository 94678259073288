import React, { useState, useEffect } from 'react';
import CenteredContent from '../components/CenteredContent';
import { BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';
import remarkMath from 'remark-math';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import { URL } from './constants';
// import CodeBlock from '../components/CodeBlock';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark, dracula, gruvboxDark } from 'react-syntax-highlighter/dist/esm/styles/prism/'; // Choose your preferred style
// import { rainbow } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function BlogPage() {

    const { url_slug } = useParams();

    // fetch from backend
    const [content, setContent] = useState([]);
    useEffect(() => {
        console.log(`${URL}/api/blog/${url_slug}`)
        fetch(`${URL}/api/blog/${url_slug}`)
        .then(res => res.json())
        .then((content) => {
          console.log('content');
        console.log(content.content); // Debugging: Check the fetched content
        setContent(content);
      })
    }, [url_slug]);

    const customStyle = {
      fontSize: '15px', // Adjust the font size as needed
    };

    const CodeBlock = ({ children }) => {
      var value = children[0]; // Extract code block content from children
      console.log('CodeBlock');
      console.log(children[0]);
      return (
        <SyntaxHighlighter 
          showLineNumbers={true} 
          language={'python'} 
          // style={coldarkDark}
          style={coldarkDark}
          customStyle={customStyle}
          wrapLines={true}
          wrapLongLines={false}
          >
          {value}
        </SyntaxHighlighter>
      );
    };


  return (
    <div>
        <CenteredContent>

      <div>
        {/* Display the content */}
      {content ? (
        <div>
          <h1>{content.title}</h1>
          <p>{content.date_created}</p>
          <ReactMarkdown
            children={content.content}
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeRaw, rehypeKatex]}
            components={{
              code: CodeBlock,
            }}
            />
        </div>
      ) : (
        <p>Loading...</p>
      )}
      </div>
    
        </CenteredContent>
    </div>
  );
}

export default BlogPage;
