import React from 'react';
import CenteredContent from '../components/CenteredContent';
import { FaLinkedin, FaTwitterSquare, FaGithubSquare } from 'react-icons/fa';

function ContactPage() {
  return (
    <div>
        <CenteredContent>
        <h1>Contact</h1>
        <div>
          <a href="https://www.linkedin.com/in/devonbrackbill"><FaLinkedin size={64}></FaLinkedin></a>
          <a href="https://twitter.com/devonbrackbill"><FaTwitterSquare size={64}></FaTwitterSquare></a>
          <a href="https://github.com/devonbrackbill"><FaGithubSquare size={64}></FaGithubSquare></a>
          
        </div>

        </CenteredContent>
    </div>
  );
}

export default ContactPage;
