import { useEffect, useState, React } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import ImageTriangle from '../imgs/triangle.png'

function CustomNavbar() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check initial width
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Navbar expand="lg" className="custom-navbar" collapseOnSelects>
      <div className="navbar-description">
        <img src={ImageTriangle} alt="Triangle logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
      </div>
      <div className="navbar-name">
        <Navbar.Brand><a href="/" style={{color: 'black', textDecoration: 'none'}}>Devon Brackbill</a></Navbar.Brand>
      </div>
      <div className="navbar-description">
        ML Engineer • Builder • Scientist
      </div>
      
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className={`ml-auto ${isMobile ? 'flex-column': ''}`}>
          <NavLink to="/" exact activeClassName="active" className="nav-link">ABOUT</NavLink>
          <NavLink to="/publications" activeClassName="active" className="nav-link">PUBLICATIONS</NavLink>
          <NavLink to="/resume" activeClassName="active" className="nav-link">RESUME</NavLink>
          <NavLink to="/portfolio" activeClassName="active" className="nav-link">PORTFOLIO</NavLink>
          <NavLink to="/blog" activeClassName="active" className="nav-link">BLOG</NavLink>
          <NavLink to="/contact" activeClassName="active" className="nav-link">CONTACT</NavLink>
          {/* <NavLink to="/ai-ml" activeClassName="active" className="nav-link">AI/ML</NavLink> */}
          {/* <NavLink to="/contact" activeClassName="active" className="nav-link">CONTACT</NavLink> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CustomNavbar;
